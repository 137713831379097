import "./ImageGenerator.css";
import defaultImage from "../Assets/default.webp";
import PromptBar from "../../PromptBar";
import Header from "../../Header";
import { useState, useRef } from "react";

const OPENAI_API_KEY = "sk-proj-r3BOb4axskD8kFfw9I1eT3BlbkFJqnbeCc85cG3NDHwpN0ZA";

function ImageGenerator() {
  let [prompt, setPrompt] = useState("");
  let [imgUrl, setImgUrl] = useState(defaultImage);
  let [isLoading, setIsLoading] = useState(false);
  let inputRef = useRef(null);


  const imageGenerator = async () => {
    if(prompt == "") {
        return 0;
    }
    setIsLoading(true);

    const response = await fetch("https://api.openai.com/v1/images/generations", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer sk-proj-r3BOb4axskD8kFfw9I1eT3BlbkFJqnbeCc85cG3NDHwpN0ZA",
            "User-Agent": "Chrome"
        },
        body: JSON.stringify({
            prompt: `${prompt}`,
            n:1,
            size: "512x512"
        })
    });
    let data = await response.json();  


    console.log(data.data[0].url);

    setImgUrl(data.data[0].url);
    setIsLoading(false);
  }


  return (
    <div className="ai-image-creator">
        < Header /> 
        <div className="img-loading">
            <img src={imgUrl} alt="default Image" />
            <div className="loading">
                <div className= {isLoading ? "loading-bar-full" : "loading-bar"}></div>
                <div className="loading-text" style={isLoading ? {display:"inline-block"} : {display:"none"} }>Loading ...</div>
            </div> 
        </div>
        <PromptBar imageGenerator={imageGenerator} inputRef={inputRef} prompt={prompt} setPrompt={setPrompt}/>
    </div>
);
}

export default ImageGenerator;
