import './App.css';

function PromptBar({prompt, setPrompt, inputRef, imageGenerator}) {

  return (
    <>
      <div className="input-box">
          <input  onChange={(e) => setPrompt(e.target.value)} type="text" value={prompt} placeholder="Type..." />
          <button onClick={()=> imageGenerator()}>Generate</button>
      </div>
    </>
  );
}

export default PromptBar;
