
function Header() {
  return (
    <>
        <div className="header">
            AI image <span>generator</span>
        </div>
    </>
  );
}

export default Header;
